.homepage-container {
    padding: 4rem 2rem; // Increased padding for better spacing
    font-family: 'Roboto', sans-serif;
    line-height: 1.6; // Slightly reduced line height for better readability
    color: #333; // Darker text color for improved readability
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff; // Changed to a cleaner white background
    border-radius: 10px; // Slightly larger border radius for a softer look
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); // Softer and larger shadow for depth
    
    h1, h2, h3, h4 {
      color: #2c3e50; // A more neutral color for headers
      font-weight: 700;
     // text-transform: uppercase;
      margin-bottom: 1rem; // Consistent margin for headers
      font-feature-settings: "palt";
    }
    
    h1 {
      font-size: 3rem; // Slightly larger font size
      margin-bottom: 1.5rem; // Reduced margin to tighten spacing
      text-align: center;
      border-bottom: 3px solid #2980b9; // Bolder bottom border
      padding-bottom: 0.5rem;
      color: #16a085; // Changed to a more sophisticated color
    }
    
    h2 {
      font-size: 2.5rem; // Increased font size for emphasis
      text-align: center;
      color: #2980b9;
    }
    
    h3 {
      font-size: 2rem; // Increased font size for emphasis
      margin-top: 2rem;
      color: #34495e;
      position: relative;
      padding-left: 1.5rem;
  
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 6px; // Increased width for more impact
        height: 80%;
        background-color: #16a085; // More sophisticated accent color
        transform: translateY(-50%);
      }
    }
    
    p {
      font-size: 1.2rem; // Slightly larger font size
      margin-bottom: 1.5rem; // Reduced margin for better spacing
      color: #555;
      line-height: 1.8; // Increased line height for readability
      text-align: justify;
  
      &:first-of-type {
        margin-top: 1rem;
      }
    }
    
    /* CTA Button Styles */
    .cta-buttons {
      display: flex;
      justify-content: center;
      gap: 2rem; // Increased gap for better separation
      margin-top: 2rem;
  
      .cta-button {
        padding: 0.75rem 2rem; // Increased padding for better clickability
        font-size: 1.2rem; // Slightly larger font size for emphasis
        color: white;
        background-color: #2980b9; // Keep the same color for consistency
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s; // Added transition for scaling effect
  
        &:hover {
          background-color: #1abc9c; // Keep the same hover color
          transform: scale(1.05); // Slightly scale on hover for interaction
        }
      }
    }
    
    /* Section styles */
    .about-section, .products-section, .why-choose-us-section, .faq-section, .contact-section {
      margin-top: 4rem; // Increased margin for separation
      padding: 2rem 1rem; // Added padding for better spacing
  
      h3 {
        color: #27ae60; // Keep the same color for consistency
        margin-bottom: 0.5rem; // Reduced margin for tighter spacing
      }
  
      p {
        margin-bottom: 1rem; // Consistent bottom margin
      }
    }
  }
  