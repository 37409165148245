$header-bg-color: #283593;
$header-font-family: 'Arial', sans-serif;
$header-font-size: 16px;

.header-container {
  border: 2px solid #1e88e5;
  border-radius: 4px;
  margin: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  .desktop-view {
    display: none; // Hide desktop view buttons by default
  }

  .mobile-view {
    display: block; // Show hamburger menu by default
  }

  @media (min-width: 768px) {
    .desktop-view {
      display: block; // Show desktop view buttons on larger screens
    }

    .mobile-view {
      display: none; // Hide hamburger menu on larger screens
    }
  }
}

.company-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.company-name {
  font-weight: bold;
  color: white;
}

.company-slogan {
  color: gray;
  font-style: italic;
}

.toolbar-items {
  display: flex;
  gap: 16px;
}

.desktop {
  display: flex;
}

.mobile-menu-button {
  display: none;
}

/* Mobile responsive styling */
@media (max-width: 768px) {
  .desktop {
    display: none;
  }

  .mobile-menu-button {
    display: flex;
    margin-left: auto;
  }

  .mobile-menu {
    width: 250px;
    padding: 20px;
  }

  .mobile-menu .MuiListItem-root {
    font-size: $header-font-size;
    font-family: $header-font-family;
    color: $header-bg-color;
  }
}
.desktop-view {
  display: flex;
}

.mobile-view {
  display: none;
}

@media (max-width: 768px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}