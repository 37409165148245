.iletisim-container {
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally
    justify-content: center; // Center content vertically
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px; // Optional: set a max width for the container
    margin: 0 auto; // Center the container on the page
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    text-align: center; // Center the heading text
  }
  
  h3 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #333;
    text-align: center; // Center the subheading text
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.6;
    margin: 10px 0;
    text-align: center; // Center paragraph text
  }
  
  .iletisim-bilgileri {
    margin: 20px 0;
  }
  
  .iletisim-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%; // Ensure the form takes the full width of the container
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input,
      textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1em;
        width: 100%; // Ensure inputs take the full width of the container
      }
  
      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }
  
    button {
      background-color: #007bff;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1em;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  