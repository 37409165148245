.hakkimizda-container {
    max-width: 800px;
    margin: 2rem auto; // Center the container
    padding: 1rem;
    background-color: #f5f5f5; // Light background for contrast
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .hakkimizda-title {
      font-size: 2rem;
      margin-bottom: 1rem;
      text-align: center; // Center title
      color: #333; // Dark color for title
    }
  
    .hakkimizda-subtitle {
      font-size: 1.5rem;
      margin-top: 1.5rem; // Space above subtitles
      margin-bottom: 0.5rem; // Space below subtitles
      color: #007bff; // Blue color for subtitles
    }
  
    .hakkimizda-text {
      font-size: 1.2rem;
      line-height: 1.6; // Improve readability
      color: #555; // Slightly lighter color for text
      margin-bottom: 1rem; // Space between paragraphs
    }
  
    .hakkimizda-values {
      margin: 1rem 0; // Space around the list
      padding-left: 1.5rem; // Indent the list
      list-style-type: disc; // Bullet points for values
      color: #555; // Same color as text
    }
  }
  