.slider-container {
    position: relative;
    overflow: hidden; /* Ensures that overflowing content is hidden */
    width: 100%; /* Ensures the container takes the full width */
  
  .slider {
    display: flex;
    align-items: center; /* Center the items vertically */
    justify-content: center; /* Center the items horizontally */
    height: 100%; /* Fill the height of the container */
  }
  
  .slider img {
    max-width: 100%; /* Ensure the image does not exceed the container width */
    max-height: auto; /* Ensure the image does not exceed the container height */
    object-fit: cover; /* Cover the container while maintaining aspect ratio */
  }
  
    button {
      background-color: rgba(255, 255, 255, 0.8);
      border: none;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      font-size: 2rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.3s;
  
      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  
    .prev {
      left: 10px;
    }
  
    .next {
      right: 10px;
    }
  }
  