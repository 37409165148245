.is-ilani-container {
    display: flex;
    flex-direction: column;
    align-items: center; // Ortalamak için
    justify-content: center;
    padding: 40px;
    background-color: #f9f9f9; // Arka plan rengi
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px; // Maksimum genişlik
    margin: 0 auto; // Ortalamak için
    text-align: center; // Metni ortalamak için
  
    h1 {
      font-size: 2.5em;
      margin-bottom: 20px;
      color: #333; // Başlık rengi
    }
  
    p {
      font-size: 1.2em;
      line-height: 1.6;
      color: #555; // Paragraf rengi
    }
  }
  